import request from "tools/request.js"

//建筑分类 添加建筑分类
class shoufei_info_post {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;


		let m_chdi_id = that.parent.m_chdi_id;
		let m_xingqi_no = that.parent.m_xingqi_no;
		let m_feiyong_info = that.parent.m_feiyong_info;
		let session = localStorage.getItem("session");

		let params = {
			m_chdi_id: m_chdi_id,
			m_xingqi_no: m_xingqi_no,
			m_feiyong_info: m_feiyong_info,
			session: session
		}

		request.post('/changdi/changdi_shoufei_set', params).then(res => {
			if (res.data.state == 200) {
				this.parent.$notify({
					title: '成功',
					message: res.data.message,
					type: 'success'
				});
			} else {
				this.parent.$notify({
					title: '失败',
					message: res.data.message,
					type: 'error'
				});
			}
			that.parent.parent.drawer_3 = false;
		})

	}
}

export default shoufei_info_post