<template>
	<div>
		<layout>
			<br />
			<el-row :gutter="20">

				<el-col :span="8">
					<el-col :span="8"><label class="yu_input_label">场地名称:</label>
					</el-col>
					<el-col :span="16">
						<el-input v-model="m_chdi_name" @input="m_input_change" placeholder="请输入内容" clearable>
						</el-input>
					</el-col>
				</el-col>
				<!-- 	<el-col :span="16">
					<el-button class="yu_right_1" @click="drawer_1 = true" plain>
						<i class="el-icon-plus"></i>添加商家
					</el-button>
				</el-col> -->
			</el-row>
			<br />


			<br />
			<el-table :data="m_changdi_list" style="width: 100%">
				<el-table-column prop="chdi_id" label="id">
				</el-table-column>
				<el-table-column prop="chdi_name" label="场地名称" >
				</el-table-column> 
				<!-- <el-table-column prop="chdi_state" label="场地状态">
					<template slot-scope="scope">
						<el-button size="mini" type="default" @click="f_shoufei_set(scope.row.chdi_id)">
							使用中
						</el-button>
						<el-button size="mini" type="default" @click="f_xinxi_set(scope.row.chdi_id)">
							空闲
						</el-button>
					</template>
				</el-table-column> -->
				<el-table-column label="操作" width="220" align="right">
					<template slot-scope="scope">
						<el-button size="mini" type="default" @click="f_shoufei_set(scope.row.chdi_id)">
							收费管理 
						</el-button>
						<el-button size="mini" type="default" @click="f_yuding_info(scope.row.chdi_id)">
							预定信息
						</el-button>
					</template>
				</el-table-column>
			</el-table>

			<br />
			<br />

			<div class="yu_content_right">
				<el-pagination background @current-change="page_current_change" layout="prev, pager, next"
					:page-size="page_size" :total="max_page">
				</el-pagination>
			</div>
			<br />
		</layout>
	<!-- 	<el-drawer title="添加商家" :visible.sync="drawer_1" size="600px">
			<changdi_add :parent="me"></changdi_add>
		</el-drawer>

		<el-drawer title="修改商家" :visible.sync="drawer_2" size="600px">
			<changdi_set :key="m_shan_id" :m_shan_id="m_shan_id" :parent="me"></changdi_set>
		</el-drawer> -->
		<el-drawer title="预定信息" :visible.sync="drawer_2" size="600px">
			<yuding_info :key="m_chdi_id" :m_chdi_id="m_chdi_id" :parent="me"></yuding_info>
		</el-drawer>
		<el-drawer title="场地费用设置" :visible.sync="drawer_3" size="600px">
			<shoufei_set :key="m_chdi_id" :m_chdi_id="m_chdi_id" :parent="me"></shoufei_set>
		</el-drawer>
		 
	</div>
</template>

<script>
	import layout from 'views/layout.vue'

	import changdi_add from './components/changdi_add/changdi_add'
	import changdi_set from './components/changdi_set/changdi_set'
	
	import yuding_info from './components/yuding_info/yuding_info'
	import shoufei_set from './components/shoufei_set/shoufei_set'
	import changdi_get from "./expand/changdi_get.js"


	export default {
		components: {
			layout,
			changdi_add,
			changdi_set,
			yuding_info,
			shoufei_set
		},
		data() {
			return {
				me: this,
				m_shan_username: "",
				m_changdi_list: [],
				drawer_1: false,
				drawer_2: false,
				drawer_3: false,
				checkedNames: [],
				page: 1,
				page_size: 10,
				max_page: 10,
				m_chdi_id: 0,
				m_chdi_name: "",
				haha: false
			}
		},
		created() {
			const that = this;

			that.changdi_get = new changdi_get(that);
			that.changdi_get.m_main();

		},
		methods: {

			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			page_current_change(val) {
				//翻页
				const that = this;

				that.page = val;
				that.changdi_get.m_main();

				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			m_input_change(val) {
				const that = this;
				that.m_shan_username = val;
				that.changdi_get.m_main();
			},
			f_shoufei_set(chdi_id) {
				//点击了收费设置
				const that = this; 
				
				console.log("123123");
				that.m_chdi_id = chdi_id;
				that.drawer_3 = true;
			},
			f_yuding_info(chdi_id) {
				//点击了预定信息
				const that = this; 
				 
				that.m_chdi_id = chdi_id;
				that.drawer_2 = true;
			},
			f_go_shangpin(shan_id) {
				const that = this;
				that.$router.push({
					path: '/shangpin',
					query: {
						"changdi_id": shan_id
					}
				});
			},
			f_go_manghe(shan_id) {
				const that = this;
				that.$router.push({
					path: '/manghe',
					query: {
						"changdi_id": shan_id
					}
				});
			},
			f_go_yiyuanchou(shan_id) {
				const that = this;
				that.$router.push({
					path: '/yiyuanchou',
					query: {
						"changdi_id": shan_id
					}
				});
			}
		}
	}
</script>