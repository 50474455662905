import request from "tools/request.js"

//建筑分类 添加建筑分类
class suoding_post {
	constructor(parent) {
		this.parent = parent
	}

	m_main(shijianduan) {
		const that = this;


		let m_chdi_id = that.parent.m_chdi_id;
		let m_riqi_no = that.parent.m_riqi_no; 
		
		let session = localStorage.getItem("session");
		
		let params = {
			m_chdi_id: m_chdi_id,
			m_riqi_no: m_riqi_no, 
			shijianduan : shijianduan,
			session: session
		}

		request.post('/changdi/jiesuo_post', params).then(res => {
			if (res.data.state == 200) {
				this.parent.$notify({
					title: '成功',
					message: res.data.message,
					type: 'success'
				});
			} else {
				this.parent.$notify({
					title: '失败',
					message: res.data.message,
					type: 'error'
				});
			}
			that.parent.parent.drawer_3 = false;
		})

	}
}

export default suoding_post