<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>


			<el-row>
				<el-col class="yu_input_label" :span="7">
					星期:
				</el-col>
				<el-col :span="17">
					<el-select v-model="m_xingqi_no"  @change="m_xingqi_change" placeholder="请选择" style="width: 100%;">
						<el-option v-for="item in m_xingqi_list" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="7">
					时间段:
				</el-col>
				<el-col :span="17">
					<el-select v-model="m_shijianduan_no" placeholder="请选择" style="width: 100%;">
						<el-option v-for="item in m_shijianduan_list" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<br />

			<block v-if="m_shijianduan_no == 0">
				<el-row>
					<el-col class="yu_input_label" :span="7">
						00:00 - 00:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[0]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						00:30 - 01:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[1]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />



				<!-- 1 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						01:00 - 01:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[2]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						01:30 - 02:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[3]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />


				<!-- 2 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						02:00 - 02:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[4]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						02:30 - 03:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[5]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />


				<!-- 3 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						03:00 - 03:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[6]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						03:30 - 04:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[7]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />


				<!-- 4 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						04:00 - 04:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[8]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						04:30 - 05:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[9]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />


				<!-- 5 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						05:00 - 05:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[10]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						05:30 - 06:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[11]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />



				<!-- 6 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						06:00 - 06:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[12]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						06:30 - 07:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[13]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />



				<!-- 7 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						07:00 - 07:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[14]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						07:30 - 08:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[15]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />


				<!-- 8 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						08:00 - 08:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[16]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						08:30 - 09:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[17]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />



				<!-- 9 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						09:00 - 09:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[18]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						09:30 - 10:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[19]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />





				<!-- 10 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						10:00 - 10:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[20]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						10:30 - 11:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[21]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />



				<!-- 11 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						11:00 - 11:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[22]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						11:30 - 12:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[23]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			</block>
			
			
			
			
			
			<block v-if="m_shijianduan_no == 1">
				
				<!-- 12 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						12:00 - 12:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[24]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						12:30 - 13:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[25]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			
			
			
				<!-- 13 -->
			
				<el-row>
					<el-col class="yu_input_label" :span="7">
						13:00 - 13:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[26]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						13:30 - 14:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[27]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			
			
				<!-- 14 -->
			
				<el-row>
					<el-col class="yu_input_label" :span="7">
						14:00 - 14:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[28]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						14:30 - 15:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[29]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			
			
				<!-- 15 -->
			
				<el-row>
					<el-col class="yu_input_label" :span="7">
						15:00 - 15:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[30]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						15:30 - 16:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[31]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			
			
				<!-- 16 -->
			
				<el-row>
					<el-col class="yu_input_label" :span="7">
						16:00 - 16:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[32]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						16:30 - 17:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[33]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			
			
				<!-- 17 -->
			
				<el-row>
					<el-col class="yu_input_label" :span="7">
						17:00 - 17:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[34]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						17:30 - 18:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[35]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			
			
			
				<!-- 18 -->
			
				<el-row>
					<el-col class="yu_input_label" :span="7">
						18:00 - 18:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[36]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						18:30 - 19:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[37]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			
			
			
				<!-- 19 -->
			
				<el-row>
					<el-col class="yu_input_label" :span="7">
						19:00 - 19:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[38]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						19:30 - 20:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[39]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			
			
				<!-- 20 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						20:00 - 20:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[40]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						20:30 - 21:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[41]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			
			
			
				<!-- 21 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						21:00 - 21:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[42]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						21:30 - 22:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[43]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			
			
			
			
			
				<!-- 22 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						22:00 - 22:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[44]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						22:30 - 23:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[45]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			
			
			
				<!-- 23 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						23:00 - 23:30 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[46]['cdjg_jiage']"></el-input>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						23:30 - 00:00 :
					</el-col>
					<el-col :span="4">
						<el-input v-model="m_feiyong_info[47]['cdjg_jiage']"></el-input>
					</el-col>
				</el-row>
				<br />
			</block>

			<el-row class="yu_content_right">
				<el-button @click="shoufei_set" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import shoufei_info_get from "./expand/shoufei_info_get.js";
	import shoufei_info_post from "./expand/shoufei_info_post.js";

	export default {

		props: ["m_chdi_id", "parent"],
		data() {
			return {
				m_xingqi_no: 1,
				m_xingqi_list: [{
					value: 1,
					label: '星期一'
				}, {
					value: 2,
					label: '星期二'
				}, {
					value: 3,
					label: '星期三'
				}, {
					value: 4,
					label: '星期四'
				}, {
					value: 5,
					label: '星期五'
				}, {
					value: 6,
					label: '星期六'
				}, {
					value: 0,
					label: '星期天'
				}],
				m_shijianduan_no: 0,
				m_shijianduan_list: [{
					value: 0,
					label: '上午'
				}, {
					value: 1,
					label: '下午'
				}],
				m_feiyong_info: [] 
			}
		},
		created() {
			const that = this;
			that.shoufei_info_get = new shoufei_info_get(that);
			that.shoufei_info_get.m_main();
		},
		methods: {
			shoufei_set() {
				const that = this;
				that.shoufei_info_post = new shoufei_info_post(that);
				that.shoufei_info_post.m_main();
			},
			m_xingqi_change(){
				const that = this;
				that.shoufei_info_get.m_main();
			},
			handleAvatarSuccess(res, file) {
				const that = this;
				console.log("dd");
				console.log(res.upload_file);

				that.m_img2 = res.img_val;
				this.m_img = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>