import request from "tools/request.js"

//建筑分类 添加建筑分类
class jianzhu_fenlei_add_post {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;



		let session = localStorage.getItem("session");



		let params = {
			m_shan_username: that.parent.m_shan_username,
			m_shan_phone: that.parent.m_shan_phone,
			m_shan_passwd: that.parent.m_shan_passwd, 
			m_img2: that.parent.m_img2,
			m_shan_type: that.parent.m_shan_type,
			m_shan_dizhi: that.parent.m_shan_dizhi,
			m_shan_dianhua: that.parent.m_shan_dianhua,
			m_shan_guanjianzi: that.parent.m_shan_guanjianzi,
			m_shan_dizhi_x: that.parent.m_shan_dizhi_x,
			m_shan_dizhi_y: that.parent.m_shan_dizhi_y,
			m_shan_shouye_tuijian: that.parent.m_shan_shouye_tuijian,
			m_shan_pindao_tuijian: that.parent.m_shan_pindao_tuijian,
			m_shan_sheng: that.parent.m_shan_sheng,
			m_shan_shi: that.parent.m_shan_shi,
			m_shan_qu: that.parent.m_shan_qu,
			m_shan_daili_type: that.parent.m_shan_daili_type,
			m_shan_up: that.parent.m_shan_up,
			m_shan_tx_user_id: that.parent.m_shan_tx_user_id,
			m_shan_tx_user_name: that.parent.m_shan_tx_user_name,
			m_shan_anzhuang_ticheng: that.parent.m_shan_anzhuang_ticheng,
			m_shan_nahuojia_1: that.parent.m_shan_nahuojia_1,
			m_shan_nahuojia_2: that.parent.m_shan_nahuojia_2,
			m_shan_nahuojia_3: that.parent.m_shan_nahuojia_3,
			m_shan_nahuojia_4: that.parent.m_shan_nahuojia_4,
			session: session
		}

		request.post('/shangjia/shangjia_add', params).then(res => {
			if (res.data.state == 200) {
				this.parent.$notify({
					title: '成功',
					message: res.data.message,
					type: 'success'
				});
			} else {
				this.parent.$notify({
					title: '失败',
					message: res.data.message,
					type: 'error'
				});
			}
			that.parent.parent.drawer_1 = false;
			that.parent.parent.shangjia_get.m_main();
		})

	}
}

export default jianzhu_fenlei_add_post