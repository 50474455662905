import request from "tools/request.js"

class shoufei_info_get {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;


		let m_chdi_id = that.parent.m_chdi_id;
		let m_xingqi_no = that.parent.m_xingqi_no; 

		let session = localStorage.getItem("session");

		let params = {
			m_chdi_id: m_chdi_id,
			m_xingqi_no: m_xingqi_no, 
			session: session
		}

		request.post('/changdi/changdi_shoufei_get', params).then(res => {
			
			if(res.data.state == 200){ 
				that.parent.m_feiyong_info = res.data.result;
			}else{
				that.parent.m_feiyong_info = [];
			}
			// that.parent.m_img = that.parent.m_shoufei_info["shan_headimg"]
			// that.parent.m_img2 = that.parent.m_shoufei_info["shan_headimg"]
			// that.parent.m_shoufei_info.shan_passwd = "";
		})

	}
}

export default shoufei_info_get