<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
	 
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					商家名称:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_username"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					手机号(登录):
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_phone"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					密码:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_passwd"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					商家展示图:
				</el-col>
				<el-col :span="16">
					<el-upload class="avatar-uploader" action="http://117.50.190.66:4013/upload" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="m_img" :src="m_img" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="8">
					经营地址:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_dizhi"></el-input>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="8">
					经营电话:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_dianhua"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					位置 纬度X:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_dizhi_x"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					位置 经度Y:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_dizhi_y"></el-input>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="8">
					省:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_sheng"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					市:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_shi"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					区:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_qu"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					代理级别:
				</el-col>
				<el-col :span="16">
					<el-select v-model="m_shangjia_info.shan_daili_type" placeholder="请选择" style="width: 100%;"
						disabled>
						<el-option v-for="item in m_shan_daili_type_list" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="8">
					上级ID:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_up" disabled></el-input>
				</el-col>
			</el-row>
			<br />



			<el-row>
				<el-col class="yu_input_label" :span="8">
					支付宝账户:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_tx_user_id"></el-input>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="8">
					支付宝姓名:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_tx_user_name"></el-input>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="8">
					安装提成:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_anzhuang_ticheng"></el-input>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="8">
					产品1 拿货价:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_nahuojia_1"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					产品2 拿货价:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_nahuojia_2"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					产品3 拿货价:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_nahuojia_3"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					产品4 拿货价:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_shangjia_info.shan_nahuojia_4"></el-input>
				</el-col>
			</el-row>
			<br />


			<br />
			<el-row class="yu_content_right">
				<el-button @click="shangjia_set" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import shangjia_info_get from "./expand/shangjia_info_get.js";
	import shangjia_info_post from "./expand/shangjia_info_post.js";

	export default {

		props: ["m_shan_id", "parent"],
		data() {
			return {
				m_shan_daili_type_list: [{
					value: 1,
					label: '省级'
				}, {
					value: 2,
					label: '市级'
				}, {
					value: 3,
					label: '区县级'
				}, {
					value: 4,
					label: '代理店'
				}],
				m_shan_username: "",
				m_shan_phone: "",
				m_shan_passwd: "",
				m_shan_nickname: "",
				m_img: '',
				m_img2: '',
				m_shan_dizhi: '',
				m_shan_dianhua: '',
				m_shan_guanjianzi: '',
				m_shan_dizhi_x: "",
				m_shan_dizhi_y: "",
				m_shan_shouye_tuijian: "",
				m_shan_pindao_tuijian: "",
				m_shan_sheng: [],
				m_shan_shi: [],
				m_shan_qu: [],
				m_shangjia_info: []
			}
		},
		created() {
			const that = this;
			that.shangjia_info_get = new shangjia_info_get(that);
			that.shangjia_info_get.m_main();
		},
		methods: {
			shangjia_set() {
				const that = this;
				that.shangjia_info_post = new shangjia_info_post(that);
				that.shangjia_info_post.m_main();
			},
			handleAvatarSuccess(res, file) {
				const that = this;
				console.log("dd");
				console.log(res.upload_file);

				that.m_img2 = res.img_val;
				this.m_img = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>