import request from "tools/request.js"

//建筑分类 添加建筑分类
class shangjia_info_post {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		 
		  
		let m_shan_id 			= that.parent.m_shan_id; 
		let m_shangjia_info 	= that.parent.m_shangjia_info;
		let m_img2 				= that.parent.m_img2;
		let session 		    = localStorage.getItem("session"); 
		 console.log("that.parent.m_img2");
		  console.log(that.parent.m_img2);
		let params = { 
			m_shan_id : m_shan_id,
			m_shangjia_info : m_shangjia_info,
		    m_img2 : m_img2,
			session : session
		}  
		
		request.post('/shangjia/shangjia_info_set',params).then(res => { 
			if(res.data.state == 200){
				this.parent.$notify({
				  title: '成功',
				  message: res.data.message,
				  type: 'success'
				}); 
			}else{
				this.parent.$notify({
				  title: '失败',
				  message: res.data.message, 
				  type: 'error'
				}); 
			}
			that.parent.parent.drawer_2 = false; 
			that.parent.parent.shangjia_get.m_main(); 
		})
		 
	}
}

export default shangjia_info_post