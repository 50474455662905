import request from "tools/request.js"

class shoufei_info_get {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;


		let m_chdi_id = that.parent.m_chdi_id;
		let m_riqi_no = that.parent.m_riqi_no; 

		let session = localStorage.getItem("session");

		let params = {
			m_chdi_id: m_chdi_id,
			m_riqi_no: m_riqi_no, 
			session: session
		}

		request.post('/changdi/yuding_info_get', params).then(res => {
			
			if(res.data.state == 200){ 
				that.parent.m_riqi_list = res.data.riqi_list;
				that.parent.m_yuding_list = res.data.yuding_list;
				that.parent.m_shijianduan_no = 0;
				 
				console.log(res);
			}else{
				that.parent.m_riqi_list = [];
			}
			// that.parent.m_img = that.parent.m_shoufei_info["shan_headimg"]
			// that.parent.m_img2 = that.parent.m_shoufei_info["shan_headimg"]
			// that.parent.m_shoufei_info.shan_passwd = "";
		})

	}
}

export default shoufei_info_get