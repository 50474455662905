import request from "tools/request.js"

class shangjia_info_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		
		  
		let m_shan_id = that.parent.m_shan_id; 
		 
		let session = localStorage.getItem("session");
		
		let params = { 
			m_shan_id : m_shan_id, 
			session : session
		}  
		
		request.post('/shangjia/shangjia_info_get',params).then(res => {
		 
			that.parent.m_shangjia_info = res.data.result[0]; 
			that.parent.m_img = that.parent.m_shangjia_info["shan_headimg"]
			that.parent.m_img2 = that.parent.m_shangjia_info["shan_headimg"]
			that.parent.m_shangjia_info.shan_passwd = "";  
		})
		 
	}
}

export default shangjia_info_get