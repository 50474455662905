<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>


			<el-row>
				<el-col class="yu_input_label" :span="7">
					日期:
				</el-col>
				<el-col :span="17">
					<el-select v-model="m_riqi_no" @change="m_riqi_change" placeholder="请选择" style="width: 100%;">
						<el-option v-for="item in m_riqi_list" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="7">
					时间段:
				</el-col>
				<el-col :span="17">
					<el-select v-model="m_shijianduan_no" placeholder="请选择" style="width: 100%;">
						<el-option v-for="item in m_shijianduan_list" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<br />

			<block v-if="m_shijianduan_no == 0">
				<el-row>
					<el-col class="yu_input_label" :span="7">
						00:00 - 00:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[0]["yuding_info"] =="0"||m_yuding_list[0]["yuding_info"] =="1"'> 
							<el-select  v-model="m_yuding_list[0]['yuding_info'] "  @change="my_yuding_change($event,0)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[0]["yuding_info"] !="0" && m_yuding_list[0]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[0]["yuding_info"] !="0"&&m_yuding_list[0]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[0]['yuding_info']"></el-input> 
						</block>
					</el-col>
					
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						00:30 - 01:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[1]["yuding_info"] =="0"||m_yuding_list[1]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[1]['yuding_info'] "  @change="my_yuding_change($event,1)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[1]["yuding_info"] !="0" && m_yuding_list[1]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[1]["yuding_info"] !="0"&&m_yuding_list[1]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[1]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />



				<!-- 1 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						01:00 - 01:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[2]["yuding_info"] =="0"||m_yuding_list[2]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[2]['yuding_info'] "  @change="my_yuding_change($event,2)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[2]["yuding_info"] !="0" && m_yuding_list[2]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[2]["yuding_info"] !="0"&&m_yuding_list[2]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[2]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						01:30 - 02:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[3]["yuding_info"] =="0"||m_yuding_list[3]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[3]['yuding_info'] "  @change="my_yuding_change($event,3)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[3]["yuding_info"] !="0" && m_yuding_list[3]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[3]["yuding_info"] !="0"&&m_yuding_list[3]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[3]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />


				<!-- 2 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						02:00 - 02:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[4]["yuding_info"] =="0"||m_yuding_list[4]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[4]['yuding_info'] "  @change="my_yuding_change($event,4)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[4]["yuding_info"] !="0" && m_yuding_list[4]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[4]["yuding_info"] !="0"&&m_yuding_list[4]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[4]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						02:30 - 03:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[5]["yuding_info"] =="0"||m_yuding_list[5]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[5]['yuding_info'] "  @change="my_yuding_change($event,5)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[5]["yuding_info"] !="0" && m_yuding_list[5]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[5]["yuding_info"] !="0"&&m_yuding_list[5]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[5]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />


				<!-- 3 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						03:00 - 03:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[6]["yuding_info"] =="0"||m_yuding_list[6]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[6]['yuding_info'] "  @change="my_yuding_change($event,6)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[6]["yuding_info"] !="0" && m_yuding_list[6]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[6]["yuding_info"] !="0"&&m_yuding_list[6]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[6]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						03:30 - 04:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[7]["yuding_info"] =="0"||m_yuding_list[7]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[7]['yuding_info'] "  @change="my_yuding_change($event,7)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[7]["yuding_info"] !="0" && m_yuding_list[7]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[7]["yuding_info"] !="0"&&m_yuding_list[7]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[7]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />


				<!-- 4 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						04:00 - 04:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[8]["yuding_info"] =="0"||m_yuding_list[8]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[8]['yuding_info'] "  @change="my_yuding_change($event,8)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[8]["yuding_info"] !="0" && m_yuding_list[8]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[8]["yuding_info"] !="0"&&m_yuding_list[8]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[8]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						04:30 - 05:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[9]["yuding_info"] =="0"||m_yuding_list[9]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[9]['yuding_info'] "  @change="my_yuding_change($event,9)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[9]["yuding_info"] !="0" && m_yuding_list[9]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[9]["yuding_info"] !="0"&&m_yuding_list[9]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[9]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />


				<!-- 5 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						05:00 - 05:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[10]["yuding_info"] =="0"||m_yuding_list[10]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[10]['yuding_info'] "  @change="my_yuding_change($event,10)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[10]["yuding_info"] !="0" && m_yuding_list[10]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[10]["yuding_info"] !="0"&&m_yuding_list[10]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[10]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						05:30 - 06:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[11]["yuding_info"] =="0"||m_yuding_list[11]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[11]['yuding_info'] "  @change="my_yuding_change($event,11)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[11]["yuding_info"] !="0" && m_yuding_list[11]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[11]["yuding_info"] !="0"&&m_yuding_list[11]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[11]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />



				<!-- 6 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						06:00 - 06:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[12]["yuding_info"] =="0"||m_yuding_list[12]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[12]['yuding_info'] "  @change="my_yuding_change($event,12)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[12]["yuding_info"] !="0" && m_yuding_list[12]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[12]["yuding_info"] !="0"&&m_yuding_list[12]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[12]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						06:30 - 07:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[13]["yuding_info"] =="0"||m_yuding_list[13]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[13]['yuding_info'] "  @change="my_yuding_change($event,13)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[13]["yuding_info"] !="0" && m_yuding_list[13]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[13]["yuding_info"] !="0"&&m_yuding_list[13]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[13]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />



				<!-- 7 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						07:00 - 07:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[14]["yuding_info"] =="0"||m_yuding_list[14]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[14]['yuding_info'] "  @change="my_yuding_change($event,14)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[14]["yuding_info"] !="0" && m_yuding_list[14]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[14]["yuding_info"] !="0"&&m_yuding_list[14]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[14]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						07:30 - 08:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[15]["yuding_info"] =="0"||m_yuding_list[15]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[15]['yuding_info'] "  @change="my_yuding_change($event,15)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[15]["yuding_info"] !="0" && m_yuding_list[15]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[15]["yuding_info"] !="0"&&m_yuding_list[15]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[15]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />


				<!-- 8 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						08:00 - 08:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[16]["yuding_info"] =="0"||m_yuding_list[16]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[16]['yuding_info'] "  @change="my_yuding_change($event,16)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[16]["yuding_info"] !="0" && m_yuding_list[16]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[16]["yuding_info"] !="0"&&m_yuding_list[16]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[16]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						08:30 - 09:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[17]["yuding_info"] =="0"||m_yuding_list[17]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[17]['yuding_info'] "  @change="my_yuding_change($event,17)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[17]["yuding_info"] !="0" && m_yuding_list[17]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[17]["yuding_info"] !="0"&&m_yuding_list[17]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[17]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />



				<!-- 9 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						09:00 - 09:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[18]["yuding_info"] =="0"||m_yuding_list[18]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[18]['yuding_info'] "  @change="my_yuding_change($event,18)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[18]["yuding_info"] !="0" && m_yuding_list[18]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[18]["yuding_info"] !="0"&&m_yuding_list[18]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[18]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						09:30 - 10:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[19]["yuding_info"] =="0"||m_yuding_list[19]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[19]['yuding_info'] "  @change="my_yuding_change($event,19)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[19]["yuding_info"] !="0" && m_yuding_list[19]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[19]["yuding_info"] !="0"&&m_yuding_list[19]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[19]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />





				<!-- 10 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						10:00 - 10:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[20]["yuding_info"] =="0"||m_yuding_list[20]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[20]['yuding_info'] "  @change="my_yuding_change($event,20)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[20]["yuding_info"] !="0" && m_yuding_list[20]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[20]["yuding_info"] !="0"&&m_yuding_list[20]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[20]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						10:30 - 11:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[21]["yuding_info"] =="0"||m_yuding_list[21]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[21]['yuding_info'] "  @change="my_yuding_change($event,21)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[21]["yuding_info"] !="0" && m_yuding_list[21]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[21]["yuding_info"] !="0"&&m_yuding_list[21]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[21]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />



				<!-- 11 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						11:00 - 11:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[22]["yuding_info"] =="0"||m_yuding_list[22]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[22]['yuding_info'] "  @change="my_yuding_change($event,22)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[22]["yuding_info"] !="0" && m_yuding_list[22]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[22]["yuding_info"] !="0"&&m_yuding_list[22]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[22]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						11:30 - 12:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[23]["yuding_info"] =="0"||m_yuding_list[23]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[23]['yuding_info'] "  @change="my_yuding_change($event,23)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[23]["yuding_info"] !="0" && m_yuding_list[23]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[23]["yuding_info"] !="0"&&m_yuding_list[23]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[23]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />
			</block>





			<block v-if="m_shijianduan_no == 1">

				<!-- 12 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						12:00 - 12:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[24]["yuding_info"] =="0"||m_yuding_list[24]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[24]['yuding_info'] "  @change="my_yuding_change($event,24)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[24]["yuding_info"] !="0" && m_yuding_list[24]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[24]["yuding_info"] !="0"&&m_yuding_list[24]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[24]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						12:30 - 13:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[25]["yuding_info"] =="0"||m_yuding_list[25]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[25]['yuding_info'] "  @change="my_yuding_change($event,25)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[25]["yuding_info"] !="0" && m_yuding_list[25]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[25]["yuding_info"] !="0"&&m_yuding_list[25]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[25]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />



				<!-- 13 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						13:00 - 13:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[26]["yuding_info"] =="0"||m_yuding_list[26]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[26]['yuding_info'] "  @change="my_yuding_change($event,26)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[26]["yuding_info"] !="0" && m_yuding_list[26]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[26]["yuding_info"] !="0"&&m_yuding_list[26]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[26]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						13:30 - 14:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[27]["yuding_info"] =="0"||m_yuding_list[27]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[27]['yuding_info'] "  @change="my_yuding_change($event,27)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[27]["yuding_info"] !="0" && m_yuding_list[27]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[27]["yuding_info"] !="0"&&m_yuding_list[27]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[27]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />


				<!-- 14 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						14:00 - 14:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[28]["yuding_info"] =="0"||m_yuding_list[28]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[28]['yuding_info'] "  @change="my_yuding_change($event,28)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[28]["yuding_info"] !="0" && m_yuding_list[28]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[28]["yuding_info"] !="0"&&m_yuding_list[28]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[28]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						14:30 - 15:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[29]["yuding_info"] =="0"||m_yuding_list[29]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[29]['yuding_info'] "  @change="my_yuding_change($event,29)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[29]["yuding_info"] !="0" && m_yuding_list[29]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[29]["yuding_info"] !="0"&&m_yuding_list[29]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[29]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />


				<!-- 15 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						15:00 - 15:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[30]["yuding_info"] =="0"||m_yuding_list[30]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[30]['yuding_info'] "  @change="my_yuding_change($event,30)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[30]["yuding_info"] !="0" && m_yuding_list[30]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[30]["yuding_info"] !="0"&&m_yuding_list[30]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[30]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						15:30 - 16:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[31]["yuding_info"] =="0"||m_yuding_list[31]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[31]['yuding_info'] "  @change="my_yuding_change($event,31)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[31]["yuding_info"] !="0" && m_yuding_list[31]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[31]["yuding_info"] !="0"&&m_yuding_list[31]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[31]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />


				<!-- 16 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						16:00 - 16:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[32]["yuding_info"] =="0"||m_yuding_list[32]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[32]['yuding_info'] "  @change="my_yuding_change($event,32)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[32]["yuding_info"] !="0" && m_yuding_list[32]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[32]["yuding_info"] !="0"&&m_yuding_list[32]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[32]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						16:30 - 17:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[33]["yuding_info"] =="0"||m_yuding_list[33]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[33]['yuding_info'] "  @change="my_yuding_change($event,33)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[33]["yuding_info"] !="0" && m_yuding_list[33]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[33]["yuding_info"] !="0"&&m_yuding_list[33]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[33]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />


				<!-- 17 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						17:00 - 17:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[34]["yuding_info"] =="0"||m_yuding_list[34]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[34]['yuding_info'] "  @change="my_yuding_change($event,34)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[34]["yuding_info"] !="0" && m_yuding_list[34]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[34]["yuding_info"] !="0"&&m_yuding_list[34]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[34]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						17:30 - 18:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[35]["yuding_info"] =="0"||m_yuding_list[35]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[35]['yuding_info'] "  @change="my_yuding_change($event,35)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[35]["yuding_info"] !="0" && m_yuding_list[35]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[35]["yuding_info"] !="0"&&m_yuding_list[35]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[35]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />



				<!-- 18 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						18:00 - 18:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[36]["yuding_info"] =="0"||m_yuding_list[36]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[36]['yuding_info'] "  @change="my_yuding_change($event,36)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[36]["yuding_info"] !="0" && m_yuding_list[36]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[36]["yuding_info"] !="0"&&m_yuding_list[36]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[36]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						18:30 - 19:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[37]["yuding_info"] =="0"||m_yuding_list[37]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[37]['yuding_info'] "  @change="my_yuding_change($event,37)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[37]["yuding_info"] !="0" && m_yuding_list[37]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[37]["yuding_info"] !="0"&&m_yuding_list[37]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[37]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />



				<!-- 19 -->

				<el-row>
					<el-col class="yu_input_label" :span="7">
						19:00 - 19:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[38]["yuding_info"] =="0"||m_yuding_list[38]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[38]['yuding_info'] "  @change="my_yuding_change($event,38)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[38]["yuding_info"] !="0" && m_yuding_list[38]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[38]["yuding_info"] !="0"&&m_yuding_list[38]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[38]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						19:30 - 20:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[39]["yuding_info"] =="0"||m_yuding_list[39]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[39]['yuding_info'] "  @change="my_yuding_change($event,39)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[39]["yuding_info"] !="0" && m_yuding_list[39]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[39]["yuding_info"] !="0"&&m_yuding_list[39]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[39]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />


				<!-- 20 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						20:00 - 20:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[40]["yuding_info"] =="0"||m_yuding_list[40]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[40]['yuding_info'] "  @change="my_yuding_change($event,40)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[40]["yuding_info"] !="0" && m_yuding_list[40]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[40]["yuding_info"] !="0"&&m_yuding_list[40]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[40]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						20:30 - 21:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[41]["yuding_info"] =="0"||m_yuding_list[41]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[41]['yuding_info'] "  @change="my_yuding_change($event,41)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[41]["yuding_info"] !="0" && m_yuding_list[41]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[41]["yuding_info"] !="0"&&m_yuding_list[41]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[41]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />



				<!-- 21 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						21:00 - 21:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[42]["yuding_info"] =="0"||m_yuding_list[42]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[42]['yuding_info'] "  @change="my_yuding_change($event,42)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[42]["yuding_info"] !="0" && m_yuding_list[42]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[42]["yuding_info"] !="0"&&m_yuding_list[42]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[42]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						21:30 - 22:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[43]["yuding_info"] =="0"||m_yuding_list[43]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[43]['yuding_info'] "  @change="my_yuding_change($event,43)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[43]["yuding_info"] !="0" && m_yuding_list[43]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[43]["yuding_info"] !="0"&&m_yuding_list[43]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[43]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />





				<!-- 22 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						22:00 - 22:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[44]["yuding_info"] =="0"||m_yuding_list[44]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[44]['yuding_info'] "  @change="my_yuding_change($event,44)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[44]["yuding_info"] !="0" && m_yuding_list[44]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[44]["yuding_info"] !="0"&&m_yuding_list[44]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[44]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						22:30 - 23:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[45]["yuding_info"] =="0"||m_yuding_list[45]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[45]['yuding_info'] "  @change="my_yuding_change($event,45)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[45]["yuding_info"] !="0" && m_yuding_list[45]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[45]["yuding_info"] !="0"&&m_yuding_list[45]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[45]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />



				<!-- 23 -->
				<el-row>
					<el-col class="yu_input_label" :span="7">
						23:00 - 23:30 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[46]["yuding_info"] =="0"||m_yuding_list[46]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[46]['yuding_info'] "  @change="my_yuding_change($event,46)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[46]["yuding_info"] !="0" && m_yuding_list[46]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[46]["yuding_info"] !="0"&&m_yuding_list[46]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[46]['yuding_info']"></el-input> 
						</block>
					</el-col>
					<el-col :span="2">
						<p> &nbsp;&nbsp;&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="7">
						23:30 - 00:00 :
					</el-col>
					<el-col :span="4">
						<block v-if=' m_yuding_list[47]["yuding_info"] =="0"||m_yuding_list[47]["yuding_info"] =="1"'>
							<el-select  v-model="m_yuding_list[47]['yuding_info'] "  @change="my_yuding_change($event,47)" placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in yu_state_list" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</block>
						<block v-if='m_yuding_list[47]["yuding_info"] !="0" && m_yuding_list[47]["yuding_info"] !="1"'> 
						<el-input :class='m_yuding_list[47]["yuding_info"] !="0"&&m_yuding_list[47]["yuding_info"] !="1"?"yu_yuding_active":""'
							v-model="m_yuding_list[47]['yuding_info']"></el-input> 
						</block>
					</el-col>
				</el-row>
				<br />
			</block>


		</div>
	</div>
</template>

<script>
	import yuding_info_get from "./expand/yuding_info_get.js";
	import yuding_info_post from "./expand/yuding_info_post.js";
	import suoding_post from "./expand/suoding_post.js";
	import jiesuo_post from "./expand/jiesuo_post.js";

	export default {

		props: ["m_chdi_id", "parent"],
		data() {
			return {
				m_riqi_no: 1,
				m_riqi_list: [{
					value: 1,
					label: '星期一'
				}, {
					value: 2,
					label: '星期二'
				}, {
					value: 3,
					label: '星期三'
				}],
				yu_state_list: [{
					value: 0,
					label: '闲'
				}, {
					value: 1,
					label: '锁定'
				}],
				m_shijianduan_no: 3,
				m_shijianduan_list: [{
					value: 0,
					label: '上午'
				}, {
					value: 1,
					label: '下午'
				}],
				m_yuding_list: []
			}
		},
		created() {
			const that = this;
			that.yuding_info_get = new yuding_info_get(that);
			that.yuding_info_get.m_main();
		},
		methods: {
			my_yuding_change(value,shijianduan){
				const that = this;
				 
				if(value == 1){
					that.suoding_post = new suoding_post(that);
					that.suoding_post.m_main(shijianduan);
				}
				 if(value == 0){
				 	that.jiesuo_post = new jiesuo_post(that);
				 	that.jiesuo_post.m_main(shijianduan);
				 }
			},
			yuding_set() {
				const that = this;
				that.yuding_info_post = new yuding_info_post(that);
				that.yuding_info_post.m_main();
			},
			m_riqi_change() {
				const that = this;
				that.yuding_info_get.m_main();
			},
			handleAvatarSuccess(res, file) {
				const that = this;
				console.log("dd");
				console.log(res.upload_file);

				that.m_img2 = res.img_val;
				this.m_img = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.yu_yuding_active input {
		background-color: #18106520;
	}
</style>